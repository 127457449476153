.c-aboutus {
  max-width: 92rem;
  margin: 4rem auto;

  .ant-col {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include iota-breakpoint(md) {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin-left: 18px;
    padding-top: 8px;
  }

  li {
    font-size: 16px;
    line-height: 26px;
  }

  &__divide {
    width: 5rem;
    border: 1px solid color(black);
    margin: 3.1rem 0 3.3rem;

    @include iota-breakpoint(md) {
      margin: 5.2rem 0 3.3rem;
    }
  }

  &__heading {
    margin-bottom: 2rem;
    &__h2 {
      margin: 5rem 0 2rem;
    }
  }

  &__paragraph {
    padding-bottom: 2rem;
    font-size: 2.4rem;
    line-height: 3.6rem;

    @include iota-breakpoint(md) {
      padding-bottom: 3rem;
    }
  }

  &__container {
    padding: 2.7rem 2.4rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.6rem;

    @include iota-breakpoint(md) {
      margin-top: 2.5rem;
      padding: 2.7rem 2.4rem 3.2rem;
    }
  }

  &__contact-btn {
    padding: 1.3rem 3rem;
  }
}