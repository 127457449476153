.g-gotable {
  &__header {
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: .08rem;
    color: color(header_grey);
    padding: .8rem 1.6rem;
    display: flex;
    > div {
      display: flex;
      align-items: center;
    }
    svg {
      margin-left: .5rem;
    }
    &--sortable:hover, &--sorted {
      color: color(white);
      cursor: pointer;
    }
  }

  &__cell {
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: .02rem;
    color: color(white);
    font-weight: 500;
  }
}

.g-gorow {
  position: relative;
  background-color: color(primary_blue);
  margin-bottom: 0.8rem;
  border-radius: 0.3rem;
  border: .1rem solid color(primary_blue);
  display: flex;
  align-items: center;
  height: 7.2rem;
  padding: 0 1.6rem;
  box-shadow: $shadow-default;
  &:hover {
    border-color: color(border_blue);
  }
  &--board {
    padding: 0 4.7rem;
    > div:first-child {
      font-weight: 700;
    }
    &:hover {
      border-color: color(primary_blue);
    }
  }
  &--sm {
    height: 6.8rem;
  }
  &__rank {
    position: absolute;
    left: .8rem;
    top: 0;
  }
}
