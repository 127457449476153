.g-icon {
  &__menu {
    display: flex;
    align-items: center;
    height: 100%;
    padding: .2rem 1rem;
    cursor: pointer;
    position: relative;

    &:hover {
      &::before {
        content: '';
        width: 3.6rem;
        height: 3.6rem;
        background: color(border_grey);
        top: calc(50% - 1.8rem);
        left: calc(50% - 1.8rem);
        position: absolute;
        border-radius: .2rem;
        z-index: 1;
      }
    }

    >* {
      z-index: 2;
    }
  }

  &__dropdown {
    top: 0 !important;
    right: 0 !important;

    >div {
      width: 100%;
      height: fit-content;
      border: 1px solid #F8F8F8;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: color(white);
      position: absolute;
      top: 15rem;
      right: 0;
      padding: 2.8rem 2.9rem 2.8rem 2.3rem;
      font-weight: normal;
      font-size: 2.4rem;
      line-height: 3rem;
      display: flex;
      flex-direction: column;

      >a {
        cursor: pointer;
        padding: .8rem 1rem;

        &:hover {
          color: color(link);
        }

        &:not(:last-child) {
          margin-bottom: 2rem;
        }
      }

      .g-header__menu--active {
        border-bottom: none !important;
        color: color(primary_blue);
      }

      @media (min-width: 1000px) {
        .g-header__menu--active {
          border-bottom: 1px solid rgba(color(primary_blue), 0.2) !important;
        }
      }
    }
  }

  &__close {
    background: #E3E5E3;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &:hover {
      display: flex;
    }
  }
}