.g-toast {
  width: fit-content;
  margin-top: 3rem;

  &__container {
    border: .1rem solid color(black);
    border-radius: .5rem;
    color: rgba(#1D2123, 0.95);
    background: rgba(white, 0.8);
    height: 5rem;
    min-height: 4rem;
    min-width: 40rem;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: .02rem;
    &--success {
      @extend .g-toast__container;
      border-color: color(success);
    }
    &--error {
      @extend .g-toast__container;
      border-color: color(error);
    }
  }

  &__msg {
    display: flex;
    align-items: center;
    > svg {
      margin: 0 .8rem;
    }
  }
}

.g-notice {
  border-radius: .5rem;
  margin: 1.6rem 0;
  border: .1rem solid color(light_blue);
  padding: 1rem 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: .02rem;
  &--error {
    border-color: color(error);
  }
  svg {
    margin-right: .7rem;
  }
}
