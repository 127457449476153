/* antd Modal */
.ant-modal {
  width: 60rem !important;
  margin-top: 5%;
  padding: 0 1.6rem;
  @include iota-breakpoint(md) {
    padding: 0;
  }
}

.ant-modal-content {
  background-color: #FFFFFF00;
  border: none;
  box-shadow: none;
}

/* antd Tooltip */
.ant-tooltip {
  .ant-tooltip-arrow {
    bottom: -.2rem;
    border-left: 1.1rem solid transparent;
    border-right: 1.1rem solid transparent;
    border-top: 1.2rem solid color(border_grey);
  }
  .ant-tooltip-inner {
    font-size: 1.4rem;
    color: color(white);
    background-color: color(border_grey);
  }
}

.ant-tooltip-placement-top {
  padding-bottom: .4rem;
}

.g-progressbar-tooltip {
  .ant-tooltip-arrow {
    border-top: 1.2rem solid color(primary_blue);
  }
  .ant-tooltip-inner {
    background-color: color(primary_blue);
  }
  &__count {
    color: color(light_blue);
    margin-left: .8rem;
  }
}

/* antd InputNumber */
.ant-input-number-handler-wrap {
  background-color: color(screen_bg);
  border-left: .1rem solid color(border_grey);
  opacity: 1;
  i, svg {
    color: color(white);
  }
  .ant-input-number-handler-up, .ant-input-number-handler-active {
    background: none;
  }
  .ant-input-number-handler-down {
    border-top: .1rem solid color(border_grey);
    background: none;
  }
}

/* antd Select Dropdown */
.ant-select-dropdown {
  width: max-content !important;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1.4rem 1.1rem;
}

.g-icon__dropdown.ant-dropdown {
  left: 0 !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-open .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border: none;
  box-shadow: none;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: black;
  background: white;
}

.ant-select-selection-placeholder {
  margin: 0;
  color: black;
  height: 100%;
  top: 0;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: black !important;
}

.ant-select-arrow {
  content: '';
  position: absolute;
  right: 2rem;
  top: 1.3rem;
  border: 1px solid #000;
  border-width: 0 0 2px 2px;
  width: 10px;
  height: 10px;
  line-height: 0;
  font-size: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ant-select-open {
  &::after {
    margin-top: .6rem;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

/* antd DatePicker */
.g-datepicker-dropdown {
  width: 48.8rem;
  padding-top: .3rem;
  font-family: Lato;

  .ant-calendar {
    width: 100%;
    color: color(white);
    border: none;
  }

  .ant-calendar-input-wrap {
    color: color(white);
    height: 5rem;
    padding: 0;
    border: none;
  }

  input {
    color: color(white);
    height: 5rem;
    font-size: 1.6rem;
    padding: .8rem 1.6rem;
    letter-spacing: .02rem;
    background-color: color(primary_blue);
    border: none;
    outline: none;
    font-family: Lato;
  }

  .ant-calendar-date-panel, 
  .ant-calendar-year-panel, 
  .ant-calendar-month-panel, 
  .ant-calendar-decade-panel {
    color: color(white);
    background-color: color(primary_blue);
    .ant-calendar-header,
    .ant-calendar-month-panel-header,
    .ant-calendar-year-panel-header,
    .ant-calendar-decade-panel-header {
      color: color(white);
      padding: 0 2.2rem;
      border-top: .1rem solid color(border_grey);
      border-bottom: .1rem solid color(border_grey);
    }
    .ant-calendar-header, 
    .ant-calendar-footer,
    .ant-calendar-month-panel-header,
    .ant-calendar-year-panel-header,
    .ant-calendar-decade-panel-header {
      a {
        color: color(white);
        &:hover {
          color: color(light_blue);
          &::before, &::after {
            border-color: color(light_blue);
          }
        }
        &::before, &::after {
          border-color: color(white);
        }
      }
    }
    .ant-calendar-footer {
      color: color(white);
      border-top: .1rem solid color(border_grey);
    }
    .ant-calendar-date {
      color: color(white);
      &:hover {
        background-color: color(border_grey);
      }
    }
    .ant-calendar-month-panel-month,
    .ant-calendar-year-panel-year,
    .ant-calendar-decade-panel-decade {
      &:hover {
        color: color(white) !important;
        background-color: color(border_grey);
      }
    }
    .ant-calendar-today {
      .ant-calendar-date {
        border-color: color(light_blue);
      }
    }
    .ant-calendar-month-panel-selected-cell > .ant-calendar-month-panel-month,
    .ant-calendar-year-panel-selected-cell > .ant-calendar-year-panel-year,
    .ant-calendar-decade-panel-selected-cell > .ant-calendar-decade-panel-decade {
      color: color(black);
      background-color: color(light_blue);
      &:hover {
        color: color(black) !important;
      }
    }
    .ant-calendar-selected-day {
      .ant-calendar-date {
        color: color(black);
        background-color: color(light_blue);
      }
    }
    .ant-calendar-disabled-cell {
      .ant-calendar-date {
        color: color(white);
        background-color: rgba(color(text_grey), 0.1);
        &::before {
          content: none;
        }
      }
    }
  }
}

.ant-spin-dot-item {
  background-color: color(primary_blue);
}

.ant-form-item-explain-error {
  margin-left: 2.1rem;
}
