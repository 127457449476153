$sizes: (
  0, 2, 3, 5, 6, 8, 10, 12, 14, 15, 16, 20, 24, 25, 30, 32, 35, 40, 45, 48, 50, 58, 60, 65, 75, 100
);

$directions: (
  r: 'right',
  l: 'left',
  t: 'top',
  b: 'bottom'
);

@each $option, $dir in $directions {
  @each $val in $sizes {
    .u-m#{$option}#{$val} {
      margin-#{$dir}: math.div($val, 10)rem;
    }

    .u-p#{$option}#{$val} {
      padding-#{$dir}: math.div($val, 10)rem;
    }
  }

  .u-m#{$option}auto {
    margin-#{$dir}: auto;
  }
}

$iota-utils-margin-extra: (
  -content: (
    null : 1rem,
    xs: 2.4rem,
    sm: 4rem,
    lg: 8rem,
    xl: 12rem,
    xxl: 16rem
  )
);

@import 'node_modules/iotacss/utilities/margin';
