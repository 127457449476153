$es-screen-width: 36rem;
$xxs-screen-width: 41.4rem;
$xs-screen-width: 48rem;
$sm-screen-width: 74rem;
$md-screen-width: 98rem;
$lg-screen-width: 120rem;
$xl-screen-width: 120rem;

$search-width: 90rem;
$coupon-width: 90rem;

// xs: 'screen and ( min-width: 30rem )', // 480px
// sm: 'screen and ( min-width: 48rem )', // 768px
// md: 'screen and ( min-width: 62rem )', // 992px
// lg: 'screen and ( min-width: 80rem )', // 1280px
// xl: 'screen and ( min-width: 90rem )', // 1440px
// xxl: 'screen and ( min-width: 100rem )', // 1600px
