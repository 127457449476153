.c-demo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
  #ddn-demo-iframe {
    padding: 0;
    width: 95%;
    min-width: 36rem;
    max-width: 80rem;
    border: 1px solid #E9E9E9;
    box-sizing: content-box;
    overflow: hidden;
    @include iota-breakpoint(md) {
      width: 80%;
    }
  }
}
