.c-animation {
  position: fixed;
  left: 50%;
  top: 10rem;
  height: 80%;
  min-height: 67rem;
  width: 60rem;
  transform: translateX(-30rem);
  &__confetti {
    @extend .c-animation;
  }
  &__rain {
    @extend .c-animation;
    line-height: 1.2rem;
    padding-top: 1.4rem;
  }
}

.c-rain {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  @keyframes drops {
    0% {
      transform: translateY(0vh);
    }
    75% {
      transform: translateY(90vh);
    }
    100% {
      transform: translateY(90vh);
    }
  }
  
  @keyframes stem {
    0% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes splat {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    80% {
      opacity: 1;
      transform: scale(0);
    }
    90% {
      opacity: 0.5;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
  
  &__back {
    z-index: 11;
    bottom: 6rem;
    opacity: 0.5;
  }

  &__drop {
    position: absolute;
    bottom: 100%;
    width: 1.5rem;
    height: 12rem;
    pointer-events: none;
    animation: drops 0.5s linear infinite;
  }

  &__stem {
    width: .1rem;
    height: 60%;
    margin-left: .7rem;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
    animation: stem 0.5s linear infinite;
  }

  &__splat {
    width: 2.2rem;
    height: 1.4rem;
    border-top: .3rem dotted rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
    animation: splat 0.5s linear infinite;
  }
}
