/*
* Iota's grid object
* https://www.iotacss.com/docs/objects/grid/
*/
$iota-objs-grid-aligned: true;
$iota-objs-grid-rev: true;
$iota-objs-grid-gutter-default: 2rem;
$iota-objs-grid-gutter-extra: ();
$iota-objs-grid-flex: $iota-global-flex;
$iota-objs-grid-equal-height: false;

@import 'node_modules/iotacss/objects/grid';
