.c-changelog {
    display: flex;
    flex-direction: column;

    .c-heading {
        margin-bottom: 5rem;
    }

    &__version {
        display: block;
        padding: 3rem 0;
        border-bottom: 1px solid #eee;

        &:last-of-type {
            border-bottom: 0;
        }
        h3 {
            font-size: 3rem;
        }
        time {
            opacity: .5;
            font-weight: 500;
        }
        ul {
            margin: 2rem 0;
            padding: 0 2rem;
            li {

            }
        }
    }
}