.g-progressbar {
  display: flex;
  height: 1.9rem;
  > div:first-child {
    border-radius: .2rem 0 0 .2rem;
  }
  > div:last-child {
    border-radius: 0 .2rem .2rem 0;
  }
}
