.c-paragraph {
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: color(black);
  margin-bottom: 2rem;

  &-nomargin {
    margin-bottom: 0;
  }

  &--lg {
    font-size: 2.4rem;
    line-height: 3.6rem;
  }

  &--md {
    font-size: 2rem;
    line-height: 3.2rem;
  }

  &--sm {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  &--loose {
    font-size: 1.6rem;
    line-height: 3rem;

    &:nth-child(odd) {
      margin-bottom: 2rem;
    }
  }

  &--center {
    text-align: center;
  }

  &__link {
    color: color(primary_green);
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: color(dark_green);
      text-decoration: underline;
    }

    &:focus {
      color: color(primary_green);
      text-decoration: underline;
    }

    &:active {
      color: #18270D;
    }

    &--disabled {
      color: #808080;
    }
  }
}