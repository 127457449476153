.g-link {
  color: color(link);
  border-bottom: 1px solid color(link);
  margin: 0 1rem;
  line-height: 2rem;
  font-weight: 700;

  &:hover {
    color: color(dark_green);
    border-color: color(dark_green);
  }
  &:focus {
    color: color(link);
    border-color: color(link);
  }
  &:active {
    color: #18270D;
    border-bottom: none;
  }
  &--disabled {
    color: #808080;
    border-bottom: none;
  }
}
