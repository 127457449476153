.c-loading {
  display: inline-flex;
  &__icon {
    animation: spin 3.7s infinite;
  }

  &--cover {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@keyframes pulse {
  20% { background-color: color(primary_green) }
  80% { background-color: #DDDCDD }
}

.c-listloading {
  &__container {
    text-align: center;
    min-height: 40rem;
    padding-top: 10rem;
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      width: 10rem;
      > div {
        width: 2.4rem;
        height: 2.4rem;
        background-color: #DDDCDD;
        border-radius: 50%;
        animation-fill-mode: both;
        animation: pulse 1.4s .2s infinite cubic-bezier(.05,.71,.84,1.06);
     
        &:nth-child(2) {
          animation-delay: .6s;
        }
  
        &:nth-child(3) {
          animation-delay: 1s;
        }
      }

    }
  }
}
