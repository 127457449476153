.g-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100vh;
}

.g-container {
  border-radius: 2rem;
  margin: auto;
  &--circle {
    border-radius: 50%;
    background-color: color(lighter_blue);
  }
  .c-search-form {
    .c-search-input {
      @include iota-breakpoint(xs) {
        width: $xs-screen-width;
      }
      @include iota-breakpoint(sm) {
        width: $sm-screen-width;
      }
      @include iota-breakpoint(md) {
        width: $md-screen-width;
        padding: 0 1rem;
      }
      @include iota-breakpoint(lg) {
        // flex: 1;
        width: $search-width;
        padding: 0 1rem;
      }
    }
  }
}
.g-container-iframe {
  background-color: #fff;
  border-radius: 2rem;
}

.g-content {
  margin: 0 auto;
  width: 100%;
  padding: 1rem;
  @include iota-breakpoint(xs) {
    width: $xs-screen-width;
  }
  @include iota-breakpoint(sm) {
    width: $sm-screen-width;
  }
  @include iota-breakpoint(md) {
    width: $md-screen-width;
    padding: 0;
  }
  @include iota-breakpoint(lg) {
    // flex: 1;
    width: $lg-screen-width;
  }
  @include iota-breakpoint(xl) {
    max-width: $xl-screen-width;
    width: $xl-screen-width;
  }

  &__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.015rem;
    color: color(white);
  }
  &__info {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.02rem;
    color: color(text_grey);
  }
  &__text {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.02rem;
    color: color(white);
    margin-bottom: 0.8rem;
  }
  &__error {
    color: color(menu_active);
  }
  &__disabled {
    color: color(text_grey);
  }
}
@media (min-width: 992px) {
  .desktop-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
}
@media (max-width: 992px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none !important;
  }
}
