/*
* The default settings file contains all the settings that iotaCSS needs to work
* https://www.iotacss.com/docs/settings/core/
*
/*
* Default global breakpoints map. These are the default breakpoints map that
* will be shared across all iotaCSS modules. You can change it also locally to
* each module.
*/
$iota-global-breakpoints: (
  es: 'screen and ( min-width: 400px )', // 25rem
  xs: 'screen and ( min-width: 480px )', // 30rem
  sm: 'screen and ( min-width: 768px )', // 48rem
  md: 'screen and ( min-width: 992px )', // 62rem
  lg: 'screen and ( min-width: 1280px )', // 80rem
  xl: 'screen and ( min-width: 1440px )', // 90rem
  xxl: 'screen and ( min-width: 1600px )', // 100rem
);

/*
* Grid columns. This setting is shared between iotaCSS grid objects and size,
* pull & push utilities. You can change it also locally to each module.
*/
$iota-global-columns: 12;

/*
* Default gutters. This setting is shared between multiple objects and
* utilities as the default value for gutters. You can change it also locally
* to each module. E.g. grid gutter, media object gutter, list gutter, etc.
*/
$iota-global-gutter-default: 2rem;

/*
* Enables flexbox across the app. If you do not want all modules to use flexbox
* you can keep this value false and set it to true separately to each one of
* them locally.
*/
$iota-global-flex: true;

/*
* Enables rtl across the app. If you enable this setting the final CSS will be
* converted to RTL.
*/
$iota-global-rtl: false;

@import 'node_modules/iotacss/settings/core';
@import 'node_modules/iotacss/tools/breakpoint';
@import 'node_modules/iotacss/tools/core';
