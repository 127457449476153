/*
* Iota's container object
* https://www.iotacss.com/docs/objects/container/
*/
$iota-objs-container-namespace: 'container';
$iota-objs-container-gutter-default: $iota-global-gutter-default;
$iota-objs-container-gutter-extra: ();
$iota-objs-container-size-default: 100%;
$iota-objs-container-size-extra: null;

@import 'node_modules/iotacss/objects/container';
