.g-modal {
  &__close {
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    cursor: pointer;
  }
}

.c-contest-modal {
  width: 58.4rem;
  &__logo {
    text-align: center;
    padding: .5rem 0;
    margin-bottom: 1.6rem;
  }
  &__title {
    text-align: center;
    margin-top: 6.4rem;
    margin-bottom: 3.2rem;
    font-size: 3.2rem;
    line-height: 4.2rem;
    letter-spacing: .05rem;
    font-weight: 700;
    color: color(white);
    text-transform: capitalize;
  }
  &__form {
    box-shadow: $shadow-active;
    width: 100%;
    background-color: color(screen_bg);
    color: color(white);
    padding: 4.8rem;
    border-radius: .5rem;
  }
  &__opponents {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 3.2rem;
    > div:not(:last-child) {
      margin-right: 1.8rem;
    }
    &::-webkit-scrollbar { 
      display: none;
    }
  }
}

.c-scorecard-modal {
  width: 93.6rem;
  padding-top: 3.1rem;
  border-radius: .5rem;
  background-color: color(screen_bg);
  color: color(white);
  position: relative;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: .02rem;
  box-shadow: $shadow-active;

  &__avatar {
    position: absolute;
    left: 4rem;
    top: -6.7rem;
  }

  &__user {
    display: flex;
    padding-left: 26rem;
  }
  &__user-info {
    min-width: 20rem;
    > div:first-child {
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: .8rem;
      font-weight: 700;
    }
  }
  &__user-data {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: .3rem 5rem 0 1.7rem;
    > div {
      min-width: 11rem;
      > div:first-child {
        color: color(text_grey);
        font-size: 1.4rem;
      }
    }
    .g-cell__dash {
      transform: translateY(1rem);
    }
  }

  &__performance {
    padding: 6.3rem 5.2rem 5.2rem 4rem;
  }
  &__total {
    font-size: 1.6rem;
    color: white;
    font-weight: 700;
    margin-left: 1.6rem;
  }
  &__contests {
    max-height: 38.4rem;
    overflow-y: auto;
    > div {
      display: flex;
      align-items: center;
      border-top: .1rem solid color(border_grey);
      height: 6.4rem;
      padding: 0 1.6rem;
    }
  }
}

.c-clearboard-modal {
  width: 40.1rem;
  padding: 3.6rem 3.2rem 3.2rem 3.2rem;
  border-radius: .4rem;
  background-color: color(screen_bg);
  color: color(white);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $shadow-active;
  > div {
    text-align: center;
  }
  &__title {
    font-size: 2rem;
    line-height: 3rem;
    letter-spacing: .03rem;
    font-weight: 700;
    margin: 1.9rem 0 .8rem;
  }
  &__info {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: .02rem;
    margin-bottom: 2.4rem;
  }
  button {
    margin-right: 2.5rem;
  }
}

.c-result-modal {
  width: 39.9rem;
  border-radius: .3rem;
  background-color: color(screen_bg);
  color: color(white);
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: .02rem;
  position: relative;
  box-shadow: $shadow-active;

  &--win {
    border: .1rem solid color(light_blue);
    padding-top: 3.4rem;
  }

  &__trophy {
    position: absolute;
    top: .4rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 6.5rem;
  }

  > div:not(:last-child) {
    border-bottom: .1rem solid color(border_grey);
  }

  &__header {
    padding: 3.2rem 0 4rem;
    text-align: center;
  }
  
  &__title {
    font-size: 3.2rem;
    line-height: 4.2rem;
    letter-spacing: .05rem;
    margin-bottom: .6rem;
    font-weight: 700;
  }

  &__contest {
    padding: 2.4rem 3.3rem;
    color: color(text_grey);

    > div > div {
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: .015rem;
    }
    
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > div:first-child {
      margin-bottom: 2.4rem;
    }

    &--win {
      color: color(white);
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: -3.3rem;
        width: 0;
        height: 0;
        border-top: .9rem solid transparent;
        border-bottom: .9rem solid transparent; 
        border-right: 1rem solid color(light_blue);
      }
    }
  }
  
  &__footer {
    padding: 2.1rem 3.2rem 3.2rem 3.2rem;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:last-child {
        font-weight: 700;
      }
    }
    > div:nth-child(2) {
      margin: 2.3rem 0 3.2rem;
    }
  }
}

.c-confirmation-modal {
  border-radius: .5rem;
  background-color: color(screen_bg);
  color: color(white);
  position: relative;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: .02rem;
  box-shadow: $shadow-active;
  &__section {
    padding: 0 3.2rem;
    &--top {
      padding-top: 3.2rem;
    }
    &--bottom {
      padding-bottom: 3.2rem;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: .6rem;
    font-size: 3.2rem;
    line-height: 4.2rem;
    letter-spacing: .05rem;
    font-weight: 700;
    color: color(white);
  }
  &__subtitle {
    margin-bottom: 4rem;
  }
  &__separator {
    border: 0;
    border-top: .1rem solid color(border_grey);
  }
  &__row {
    display: flex;
    justify-content: space-between;
    margin: 2.4rem 0;
    &:last-child {
      margin-bottom: 3.2rem;
    }
    span {
      &:first-child {
        color: color(text_grey);
        font-size: 1.4rem;
        letter-spacing: .1rem;
      }
      &:last-child {
        color: color(white);
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: .02rem;
        text-align: right;
      }
    }
  }
}