.c-heading {
  font-weight: bold;
  font-size: 4rem;
  line-height: 5.2rem;
  color: color(primary_blue);
  margin: 2rem 0 1rem;

  &--center {
    text-align: center;
  }

  &--sm {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  &--xs {
    font-size: 2rem;
    line-height: 3.2rem;
  }

  &--short {
    max-width: 52.6rem;
  }

  &__green {
    color: color(primary_green);
  }
}
