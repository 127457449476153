.c-toggle-show {
  color: color(link);
  display: flex;
  align-items: center;
  height: 2rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;

  > span:first-child {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 12px;
    text-decoration-line: underline;
    margin-right: .7rem;
  }
}
@media (max-width: 992px) {
  .c-toggle-show {
    margin-top: 2rem;
  }
}
