.c-faq-item {
  .ant-collapse-item {
    background: color(white);
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.09);
    border-radius: 10px !important;
    margin-bottom: .9rem;
    padding: 1.5rem 1.2rem;

    .ant-collapse-header .ant-collapse-arrow {
      font-size: 2.2rem;
      top: 0;
      transform: translateY(1.4rem);
    }
  }
}

.c-paragraph {
  &__faq {
    margin-bottom: 0;
    &-after {
      margin: 3rem 0 0;
    }
  }
}