$sizes: (
  5: 5%, 
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%, 
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  75: 75%,
  80: 80%,
  100: 100%
);

@each $name, $val in $sizes {
  .u-w#{$name} {
    width: $val;
  }
}