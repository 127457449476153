.g-header {
  width: 100%;
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    display: none;
  }

  &__notice {
    width: 100%;
    background-color: color(primary_blue);
    display: flex;
    justify-content: center;
    align-items: center;
    color: color(white);
    font-weight: 600;

    >div {
      height: 6.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      width: $es-screen-width;

      @include iota-breakpoint(xs) {
        width: $xs-screen-width;
      }

      @include iota-breakpoint(sm) {
        justify-content: flex-end;
        text-align: right;
        height: 4rem;
        width: $sm-screen-width;
      }

      @include iota-breakpoint(md) {
        width: $md-screen-width;
      }

      @include iota-breakpoint(lg) {
        width: $lg-screen-width;
      }

      @include iota-breakpoint(xl) {
        max-width: $xl-screen-width;
        width: $xl-screen-width;
      }
    }
  }

  &__title {
    height: 7.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 1.5rem;
    margin-bottom: 2.4rem;

    @include iota-breakpoint(xs) {
      width: $xs-screen-width;
    }

    @include iota-breakpoint(sm) {
      width: $sm-screen-width;
    }

    @include iota-breakpoint(md) {
      height: 10rem;
      margin-bottom: 1.3rem;
      padding: 0 1.1rem;
      width: $md-screen-width;
    }

    @include iota-breakpoint(lg) {
      width: $lg-screen-width;
    }

    @include iota-breakpoint(xl) {
      max-width: $xl-screen-width;
      width: $xl-screen-width;
    }
  }

  &__logo {
    display: flex;
    align-items: center;

    >img:first-child {
      height: 5rem;
    }

    >img:nth-child(2) {
      padding-left: .4rem;
      height: 1.9rem;
      transform: translateY(.4rem);

      @include iota-breakpoint(md) {
        padding-left: .6rem;
        height: 2.2rem;
      }
    }
  }

  &__menu {
    >a {
      line-height: 1.8rem;
      color: color(menu_link);
      margin: 0 1rem;
      padding: 1rem;

      &:hover {
        border-bottom: .2rem solid color(primary_blue);
      }

      &:last-child {
        margin-right: 0;
      }
    }

    >svg {
      transform: translateY(.6rem);
    }

    &--active {
      border-bottom: .2rem solid color(menu_link);
    }
  }
}
