.g-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  padding: 0 2rem 4.3rem;
  flex-direction: column;

  @include iota-breakpoint(sm) {
    margin: 4.4rem 0;
    padding: 0 2rem 3.6rem;
  }

  @media print {
    display: none;
  }

  &__copyright,
  &__links {
    color: black;
    text-align: center;
    line-height: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__copyright {
    flex-direction: column;
    font-size: 1.6rem;

    @include iota-breakpoint(sm) {
      flex-direction: row;
      font-size: 1.8rem;
    }
  }

  &__divider {
    height: 2.2rem;
    margin: 0;
    border-left: 1px solid black;
  }

  &__partners {
    display: block;
    margin-bottom: 2rem;

    a {
      font-size: 1.8rem;
    }
  }
}
