.g-user {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: .02rem;

  &--inactive {
    color: color(text_grey);
  }

  &--column {
    flex-direction: column;
    &:hover {
      color: color(light_blue);
      cursor: pointer;
    }
  }

  &__win, &__loss, &__tie {
    font-size: 1.8rem;
    line-height: 2.2rem;
    letter-spacing: .015rem;
    padding-left: 1.2rem;
  }

  &__win {
    font-weight: 700;
  }

  &__loss, &__tie {
    color: color(text_grey);
  }

  &__avatar {
    border-radius: 50%;
  }

  &__name {
    font-weight: 700;
    padding-left: .8rem;
  }

  &__opponent {
    width: 100%;
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: .02rem;
    font-weight: 700;
    white-space: nowrap;
    margin: .8rem 0 .4rem;
    text-align: center;
  }

  &__score {
    flex: 1;
    text-align: right;
    &--win {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        right: -3.2rem;
        width: 0;
        height: 0;
        border-top: 1.1rem solid transparent;
        border-bottom: 1.1rem solid transparent; 
        border-right: 1.2rem solid color(light_blue);
      }
    }
  }

  &__dep {
    font-size: 1.2rem;
  }
}

.g-avatars {
  display: flex;
  position: relative;
  width: 7.2rem;
  height: 4rem;
  > div {
    position: absolute;
    cursor: pointer;
  }
  &__front {
    z-index: 3;
    border: .1rem solid color(light_blue);
  }
  &__back {
    left: 3.2rem;
  }
}
