$vals: (
  left: flex-start,
  center: center,
  right: flex-end,
  space-between: space-between,
  space-around: space-around,
  space-evenly: space-evenly
);

$sizes: (
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 48
);

@each $name, $val in $vals {
  .u-#{$name} {
    display: flex;
    justify-content: $val;
  }
  .u-align-#{$name} {
    display: flex;
    justify-content: $val;
    align-items: center;
  }
}

@each $size in $sizes {
  .u-flex#{$size} {
    flex: math.div($size, 100);
  }
}
