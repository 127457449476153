.c-privacy-policy {
  max-width: 92rem;
  margin: 4rem auto;

  &__heading {
    padding-bottom: 4rem;
    @include iota-breakpoint(md) {
      padding-bottom: 6rem;
    }
  }
}
