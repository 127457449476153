/**
 * IotaCSS reset
 */
 $iota-base-initialize-box-sizing:           'border-box-1';
 $iota-base-initialize-heading-size:         true;
 $iota-base-initialize-form-elements:        true;
 $iota-base-initialize-html5-form-elements:  true;

@import 'node_modules/iotacss/base/initialize';

/**
* ADK-Defined initialization rules
* font-size: 62.5% === 10px in 99% of cases, and it lets us use REM with base-10 multiplication, making development easier
* Example: 1.4rem = 14px
*/

html { 
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem/1.6;
  font-family: Inter, sans-serif;
  // regular: 400, medium: 500, bold: 700, heavy: 800;
  font-weight: 400;
  color: color(black);
  -webkit-print-color-adjust: exact !important;
}

* { 
  box-sizing: border-box;
}

/**
* Keep headings from going bold on us
*/
h1, h2, h3, h4, h5, h6 { 
  font-weight: 700;
}

a, a:hover, a:active {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
