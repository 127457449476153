.c-contact {
  max-width: 92rem;
  margin: 4rem auto;

  &__heading {
    padding-bottom: 2.1rem;
    @include iota-breakpoint(md) {
      padding-bottom: 1.6rem;
    }
  }

  &__contact-info {
    margin-top: 4rem;
    @include iota-breakpoint(md) {
      margin-top: 9rem;
    }
    .c-heading {
      margin-bottom: .8rem;
    }
  }

  &__message-form {
    background: color(light_green);
    border-radius: 20px;
    margin-top: 4rem;
    padding: 3.4rem 2rem 4.3rem 1.9rem;
    @include iota-breakpoint(md) {
      margin-top: 6rem;
      padding: 3.4rem 3.9rem 3.6rem 3.5rem;
    }
    .c-heading {
      margin-bottom: 1.9rem;
    }
    .ant-row {
      margin-bottom: .8rem;
      @include iota-breakpoint(md) {
        margin-bottom: 1.6rem;
      }
    }
  }

  &__message-link {
    margin-top: 4rem;
    padding: 0;
    @include iota-breakpoint(md) {
      margin-top: 6rem;
      padding: 3.4rem 3.9rem 3.6rem 3.5rem;
    }
  }

  &__textarea {
    height: auto;
  }

  &__submit {
    margin-top: .8rem;
    @include iota-breakpoint(md) {
      margin-top: 0;
    }
  }

  &__divide {
    width: 5rem;
    border: 1px solid color(black);
    margin: 3rem 0;
  }

  #faqs {
    margin-top: 7rem;
    padding: 5.4rem 1.6rem 4.2rem;
    > .c-heading {
      padding-bottom: 2.2rem;
    }
    @include iota-breakpoint(md) {
      margin-top: 4rem;
      padding: 7.1rem 7.4rem 5.4rem;
    }
  }
}
