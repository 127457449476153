.g-input {
  color: color(black);
  background-color: color(white);
  outline: none;
  box-shadow: none;
  border: none;
  padding: 0;
  width: 100%;
  font-size: 1.75rem;
  font-weight: normal;
  line-height: 1.6rem;
  height: 2.1rem;
  padding-right: 2.5rem;

  &::placeholder {
    color: color(black);
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    display: none;
  }

  &__container {
    height: 1.8rem;
    position: relative;
  }

  &__textarea-container {
    height: fit-content;
    position: relative;
  }

  &:focus, &:hover {
    ~ .g-input__icon {
      display: flex;
    }
  }

  &__form-item {
    position: relative;
    background: #FFFFFF;
    border: .1rem solid #A7A8AA;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 1.5rem 2.1rem 1.4rem;
    input::placeholder, textarea::placeholder {
      opacity: 0.5;
    }
    &--required {
      margin-right: 6.4rem;
      @include iota-breakpoint(md) {
        margin-right: 0;
      }
    }
    &--full {
      @include iota-breakpoint(md) {
        margin-right: 7rem;
      }
    }
  }

  &__label {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.2rem;
    margin-bottom: .5rem;
    color: #000000;
  }

  &__required {
    font-size: 1.4rem;
    line-height: 1.6rem;
    position: absolute;
    top: 2.7rem;
    right: -6.4rem;
    @include iota-breakpoint(md) {
      right: -7.3rem;
    }
  }
}

.g-search {
  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 33.6rem;
    max-width: 48.8rem;
    height: 5rem;
    padding: .5rem .9rem .5rem 1.6rem;
    font-size: 1.6rem;
    background-color: #0D0F11;
  }
  
  &__value-container {
    margin: 0;
    padding: 0;
  }
  
  &__input {
    color: color(white);
  }
  
  &__menu {
    position: absolute;
    top: 4.6rem;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    z-index: 4;
    background-color: color(primary_blue);
  }

  &__option {
    cursor: pointer;
    background-color: color(primary_blue);
    padding: 1.2rem 2.4rem 1.2rem 1.6rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: .02rem;
    
    div {
      align-items: center;
    }

    &--selected {
      background-color: color(border_grey);
      .g-search__username {
        color: color(white);
      }
    }

    &:hover {
      background-color: color(border_grey);
      .g-search__username {
        color: color(light_blue);
        font-weight: 700;
      }
    }

    &--disabled {
      cursor: initial;
      &:hover {
        background-color: color(primary_blue);
        .g-search__username {
          color: color(text_grey);
          font-weight: 400;
        }
      }
    }
  }
  
  &__indicator-separator {
    display: none;
  }
}

.g-select {
  position: relative;
  width: 100%;
  height: 7rem;
  border: .1rem solid #A7A8AA;
  border-radius: 1rem;
  padding-top: 1.5rem;
  background-color: color(white);
  
  @include iota-breakpoint(md) {
    width: 22rem;
  }

  &--full {
    width: 100%;
  }

  &__label {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.2rem;
    color: black;
    padding-left: 2.1rem;
  }

  &__option {
    color: color(black);
    background-color: color(white) !important;
    font-size: 1.6rem;
    line-height: 4rem;
    padding: 0 1rem;
    border-radius: .5rem;
    cursor: pointer;
    &:hover {
      background-color: color(content_bg) !important;
    }
  }

  .ant-select, .ant-select-focused {
    width: 100%;
    .ant-select-selector {
      border: none !important;
      box-shadow: none !important;
      padding: 0 1.1rem 0 2.1rem !important;
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 1.6rem;
      color: black;
    }
    .ant-select-selection-search {
      padding-left: 1rem;
    }
  }
}

.g-datepicker {
  width: 100%;

  input {
    font-family: Lato;
    color: color(white);
    height: 5rem;
    font-size: 1.6rem;
    padding: .8rem 1.6rem;
    background-color: color(primary_blue);
    border-radius: .2rem;
    letter-spacing: .02rem;
    border: none;
    outline: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  i {
    font-size: 1.8rem;
    width: 1.8rem;
    height: 1.8rem;
    color: color(white);
    right: 1.7rem;
    margin-top: -.9rem;
  }
}